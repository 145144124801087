$min_width: 400;
$max_width: 800;

.hrm-main-banner {
	width: 100%;
	height: 100vh;
	position: relative;
	overflow: hidden;

	&.is-page {
		// 高さはヘッダ分間引く
		height: calc(100vh - 200px);
		//background-size: cover;
		background-position: center center;
		@include loading;
	}

	&.is-plan {
		height: 330px;
		background-size: cover;
		background-position: center center;
	}

	&.is-bottom {
		background-position: center bottom;
	}

	@at-root .is-vertical & {
		height: #{$max_full_size_height}px !important;
	}

	&__loading {
		position: absolute;
		left: 50%;
		top: 50%;
		margin: -30px 0 0 -60px;
	}

	&__item {
		position: absolute;
		top: 0;
	}

	.hrm-main-banner-nav {
		position: absolute;
		top: 0;
	}
}

.hrm-main-banner__background {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	&__inner {
		position: relative;
		width: 100%;
		height: 100%;
	}
}

.hrm-main-banner__item {
	height: 100%;
	width: 100%;
	&__inner {
		position: relative;
		height: 100%;
		width: 100%;
		overflow: hidden;
	}
	&__photo {
		transform: rotate(0.1deg); // microsoftバグ対策
		background-size: cover;
		background-position: center center;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}

.hrm-main-banner__title-nav {
	position: absolute;
	width: 100%;
	height: 100%;
}
.hrm-main-banner__title-wrapper {
	position: absolute;
	bottom: 78px;
	width: 100%;
	height: 30%;
}


.hrm-main-banner__title {
	position: absolute;
	bottom: 12%;
	left: 7%;
	color: #FFF;
	line-height: 1;
	display: table;
	//transform: rotate(.1deg);

	&__left {
		display: table-cell;
		vertical-align: top;
		padding-right: 1em;
		border-right: 1px solid #FFF;
		$min_font: 14;
		$max_font: 18;
		font-size: calc( #{$min_font}px + (#{$max_font} - #{$min_font}) * ( (100vw - #{$min_width}px) / ( #{$max_width} - #{$min_width}) ));
	}
	&__right {
		display: table-cell;
		vertical-align: top;
		padding-left: 1.4em;
	}

	&__name__main {
		display: inline-block;
		$min_font: 20;
		$max_font: 30;
		font-size: calc( #{$min_font}px + (#{$max_font} - #{$min_font}) * ( (100vw - #{$min_width}px) / ( #{$max_width} - #{$min_width}) ));
	}
	&__name__sub {
		display: inline-block;
		padding-left: .5em;
		font-style: italic;
		letter-spacing: .1em;
		&:before {
			content: "/";
			display: inline-block;
			padding-right: .5em;
		}
		.hrm-icon-arw {
			margin-left: 10px;
			vertical-align: middle;
			display: none;
		}
	}

	&__description {
		margin-top: .6em;
		$min_font: 14;
		$max_font: 18;
		font-size: calc( #{$min_font}px + (#{$max_font} - #{$min_font}) * ( (100vw - #{$min_width}px) / ( #{$max_width} - #{$min_width}) ));
	}

	.hrm-icon-arw2 {
		margin-left: .5em;
	}
}

a.hrm-main-banner__title {
	text-decoration: none;
	color: #FFF !important;
	display: block;
	transition: opacity .2s linear;
	&:hover {
		text-decoration: none;
		opacity: .6 !important;
	}
	.hrm-icon-arw {
		display: inline-block !important;
	}
}

.hrm-main-banner__scroll {
	$min_font: 12;
	$max_font: 14;
	font-size: calc(#{$min_font}px + (#{$max_font} - #{$min_font}) * ((100vw - #{$min_width}px) / (#{$max_width} - #{$min_width})));

	color: #FFF;
	font-style: italic;
	position: absolute;
	width: 100%;
	text-align: center;
	bottom: 0;

	transition: all 1s $easeOutQuad;
	transform: translateY(200px);
	&.is-active {
		transform: translateY(0);
	}
	&:after {
		content: "";
		display: block;
		height: 50px;
		width: 1px;
		background: #FFF;
		margin: 5px auto 0 auto;
	}
}

.hrm-main-banner__nav {
	position: absolute;
	top: 50%;
	margin-top: -26px;
	a.is-disabled {
		pointer-events: none;
	}
	&.is-prev {
		left: 20px;
	}
	&.is-next {
		right: 20px;
	}
}

.hrm-main-banner__card {
	position: absolute;
	// スクロールバーにかぶるのでデザインより広めにr, bを取る
	right: 40px;
	bottom: 40px;
	width: 200px;
	text-decoration: none !important;
	background: #FFF;
	padding: 1px;
	img {
		transition: all .3s linear;
	}
	&__inner {
		position: relative;
		width: 100%;
		height: 100%;
		&:before {
			content: "";
			position: absolute;
			left: 50%;
			top: -15px;
			width: 2px;
			height: 30px;
			background: $gold;
		}
	}
	&__description {
		padding: 14px;
		.hrm-icon-line-arw2 {
			float: right;
			position: relative;
			&:before {
				width: 50px;
				left: -50px;
			}
			i:before {
				transform: scale(0.6) translateY(2px);
			}
		}
	}
	&__title {
		font-size: 14px;
		line-height: 1.7;
		span {
			font-size: 18px;
		}
	}
	time {
		color: $gold;
		font-size: 14px;
	}
	&:hover {
		img {
			opacity: .8;
		}
		.hrm-icon-line-arw2 {
			&:before {
				transform: scaleX(2);
			}
		}
	}
}
