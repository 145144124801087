.hrm-label-box {
	display: inline-block;
	font-size: 10px;
	font-style: italic;
	padding: 0 18px;
	line-height: 21px;
	border: 1px solid $text-color;
}

.hrm-label-box2 {
	display: inline-block;
	background: $gold;
	color: #FFF;
	font-size: 14px;
	line-height: 2;
	padding: 0 10px;
}
