@font-face {
	font-family: "glyph";
	src: url('/assets/fonts/glyph.eot');
	src: url('/assets/fonts/glyph.eot?#iefix') format('eot'),
	url('/assets/fonts/glyph.woff') format('woff'),
	url('/assets/fonts/glyph.ttf') format('truetype'),
	url('/assets/fonts/glyph.svg#glyph') format('svg');
	font-weight: normal;
	font-style: normal;
}

.glyph:before {
	display: inline-block;
	font-family: "glyph";
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.glyph-lg {
	font-size: 1.3333333333333333em;
	line-height: 0.75em;
	vertical-align: -15%;
}
.glyph-2x { font-size: 2em; }
.glyph-3x { font-size: 3em; }
.glyph-4x { font-size: 4em; }
.glyph-5x { font-size: 5em; }
.glyph-fw {
	width: 1.2857142857142858em;
	text-align: center;
}

.glyph-arw:before { content: "\EA01" }
.glyph-email:before { content: "\EA02" }
