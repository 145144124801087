/*
* hrm-block
*/
.hrm-block {
	display: flex;
	height: 650px;
	&.is-reverse {
		flex-direction: row-reverse;
	}
	&__col {
		width: 50%;
		height: 100%;
		position: relative;
	}
	&__col.is-photo {
		//background-size: cover;
		@include loading;
		background-position: center center;
		//&.is-bottom {
		//	background-position: center bottom;
		//}
		//&.is-top {
		//	background-position: center top;
		//}
	}
}

/*
* hrm-block2
*/
.hrm-block2 {
	&__row {
		display: flex;
		max-width: 980px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		padding-bottom: 140px;
	}
	&__col {
		width: 50%;
	}
	&__col.is-logo {
		padding-right: 80px;
	}
	&__logo {
		width: 100%;
		border: 1px solid $gray-light;
		position: relative;
		padding: 100px 54px;
		img {
			width: 100%;
			height: auto;
			@include loading;
		}
	}
	&__description {
		position: relative;
		top: -11px;
	}
	&__text {
		p {
			margin-top: 50px;
			line-height: 2;
			font-size: 16px;
		}
	}
}

/*
* hrm-block3
*/
.hrm-block3 {
	&__lead {
		text-align: center;
		font-size: 18px;
		line-height: 2;
		margin-top: 40px;
		margin-bottom: 70px;
	}
	.hrm-grid {
		margin-bottom: 80px;
	}
	.hrm-block4 {
		margin-top: 120px;
	}
}

/*
* hrm-block4
*/
.hrm-block4 {
	margin: 0 auto;
	width: 490px;
	&__attention {
		margin-top: 10px;
		font-size: 12px;
		//line-height: 3;
		line-height: 1.5;
	}
}

/*
* hrm-block5
*/
.hrm-block5 {
	width: 100%;
	max-width: 980px;
	min-height: 400px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	.hrm-block5__col:first-child {
		width: 400px;
		height: 400px;
		@include loading;
	}
	.hrm-block5__col:last-child {
		flex: 1;
		padding-left: 80px;
	}
	&__photos {
		position: relative;
		width: 100%;
		height: 400px;
		margin-top: 15px;
		&__item {
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}

/*
* hrm-block6
*/
.hrm-block6 {
	font-size: 16px;
	line-height: 2;
	text-align: center;
	width: 100%;
	max-width: 850px;
	margin-left: auto;
	margin-right: auto;
}

/*
* hrm-block7
*/
.hrm-block7 {
	width: 100%;
	//min-height: 455px;
	display: flex;
	@include clearfix;
	&.is-reverse {
		flex-direction: row-reverse;
	}
	&__col {
		position: relative;
		width: 50%;
		&.is-photo {
			@include loading;
		}
	}
	.hrm-photo-set {
		height: 400px;
		.hrm-photo {
			background-size: cover;
		}
	}
	.hrm-photo {
		background-position: left center;
	}
	.hrm-block-content2 {
		padding-left: 80px;
		width: 480px;
	}
	@at-root .is-reverse .hrm-block-content2 {
		float: right;
		padding-left: 0;
		padding-right: 80px;
	}
}

/*
* hrm-block8
*/
.hrm-block8 {
	display: flex;
	width: 980px;
	margin-left: auto;
	margin-right: auto;

	.hrm-button2 {
		margin-bottom: 1px;
	}
	&__pdf-btn {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding-right: 10px;
		font-size: 15px;
		a {
			display: block;
			text-decoration: none;
			background: $gray-light;
			line-height: 60px;
			padding: 0 20px;
			position: relative;
			width: 240px;
			i {
				position: absolute;
				top: 50%;
				right: 20px;
				transform: translateY(-50%);
			}
		}
	}
	&__col {
		min-height: 480px;
		position: relative;
		&.is-photo {
			@include loading;
		}
		&.hrm-lazy-load.is-photo {
			background: none !important;
		}
	}

	&__col__item {
		position: absolute;
		top: 0;
		left: 0;
	}
	.hrm-block8__col:first-child {
		width: 250px;
	}
	.hrm-block8__col:last-child {
		flex: 1;
		position: relative;
		width: 100%;
		border: 3px solid $gold;
		height: 480px;
	}
}

/*
* hrm-block9
*/
.hrm-block9 {

	&__head {
		background: #fbfafa;
		border: 1px solid $gray-light;
		padding: 25px 30px;
		position: relative;
		&__row {
			display: table;
			width: 100%;
		}
		&__col {
			display: table-cell;
			&.is-last {
				width: 270px;
			}
		}
		&__title {
			margin-bottom: 15px;
			line-height: 26px;
		}
		&__title-text {
			font-weight: bold;
		}
		&__description {
			font-style: italic;
			font-size: 16px;
			line-height: 1.7;
		}
		&__price {
			color: $gold;
			font-size: 24px;
			margin-bottom: 3px;
		}
		.hrm-label-box2 {
			float: left;
			margin-right: 15px;
			line-height: 26px;
		}
		.hrm-button3 {
			position: absolute;
			width: 300px;
			bottom: 0;
			right: 0;
			> .inner {
				position: relative;
				width: 100%;
				height: 100%;
			}
			.hrm-icon-toggle2 {
				position: absolute;
				right: 0;
				top: 50%;
				margin-top: -6px;
				transition: all .3s ease;
			}
			&.is-close {
				.hrm-icon-toggle2 {
					transform: rotate(225deg);
				}
			}
		}
	}

	&__body {
		background: #fbfafa;
		padding: 49px;
		border: 1px solid $gray-light;
		border-top: 0;

		.col-xs-12 {
			&:first-child {
				padding-right: 15px;
			}
			&:last-child {
				padding-left: 15px;
			}
		}
		.hrm-h-ul {
			font-size: 16px;
		}
		.row {
			margin-bottom: 30px;
		}
	}
}

/*
* hrm-block10
*/
.hrm-block10 {
	width: 100%;
	max-width: 980px;
	margin: 0 auto;
	min-height: 550px;
	position: relative;
	.hrm-photo-set {
		width: 100%;
		height: 550px;
	}
}

/*
* hrm-block11
*/
.hrm-block11 {
	width: 100%;
	max-width: 900px;
	margin: 0 auto;

	&__contents {
		background: #fbf8f4;
		padding: 50px;
		text-align: center;
	}
	&__title {
		font-size: 24px;
		font-weight: normal;
		//font-style: italic;
	}
	&__sep {
		color: $gold;
		position: relative;
		height: 2em;
		margin: 20px auto;
		width: 395px;
		&:before {
			content: "";
			display: block;
			height: 1px;
			width: 100%;
			background: $gold;
			position: absolute;
			top: 50%;
		}
		i {
			width: 56px;
			position: absolute;
			left: 50%;
			top: 2px;
			text-align: center;
			background: #fbf8f4;
			margin-left: -28px;
			&:after {
				content: "\EA01";
				display: block;
				font-family: "glyph";
				font-size: 20px;
				font-style: normal;
				transform:rotate(90deg);
			}
		}
	}
	dl {
		//font-style: italic;
		line-height: 1.7;
		margin-top: 20px;
	}
	dt {
		color: $gold;
		font-size: 16px;
		margin-bottom: 5px;
	}
	dd {
		font-size: 16px;
	}
}

.hrm-block-content {
	height: 100%;
	@include clearfix;
	&__main {
		width: 100%;
		//max-width: 610px;
		height: calc(100% - 80px);
		display: table;
		@at-root .is-reverse & {
			float: right;
		}
	}
	&__main__inner {
		display: table-cell;
		vertical-align: middle;
		padding: 0 80px;
	}

	&__text {
		margin-top: 40px;
		line-height: 2;
	}

}

.hrm-block-content2 {
	&__text {
		//font-style: italic;
		font-size: 16px;
		line-height: 2;
		margin-top: 40px;
		p {
			margin-bottom: 1em;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.hrm-block-footer {
	border-top: 1px solid $gray-light;
	display: flex;
	align-items: center;
	&__cell.is-left {
		font-size: 12px;
		line-height: 1;
		padding: 0 40px;
		text-align: center;
	}
	&__cell.is-center {
		flex: 1;
		text-align: center;
	}
	&__cell.is-right {
		width: 200px;
		text-align: right;
	}
	a {
		@extend .hrm-alpha-hover;
	}
}

.hrm-block-footer2 {
	border-top: 1px solid $gray-light;
	font-size: 14px;
	a {
		@extend .hrm-alpha-hover;
		text-decoration: none;
		&:hover {
			text-decoration: none;
		}
	}
	img {
		transform: translateY(-3px);
	}
	ul {
		display: flex;
		justify-content: center;
		padding: 40px;
	}
	li {
		line-height: 1;
		// ブラウザ幅の都合でデザインより幅を小さめにする
		padding: 0 20px;
		a {
			line-height: 30px;
		}
	}
}

.hrm-block-footer3 {
	@include clearfix;
	border-top: 1px solid $gray-light;
	position: relative;
	height: 80px;
	a {
		text-decoration: none !important;
		@extend .hrm-alpha-hover;
	}
	&__main {
		position: absolute;
		line-height: 80px;
		width: 100%;
		text-align: center;
		font-size: 24px;
		&:before {
			content: "";
			position: absolute;
			width: 2px;
			height: 30px;
			background: $gold;
			top: -15px;
		}
	}
	&__sub {
		float: right;
		position: relative;
	}
}

.hrm-block-footer4 {
	padding: 50px 0;
	width: 100%;
	background: $gray-light;
	&__row {
		width: 980px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
	}
	&__social {
		background: #FFF;
		width: 180px;
		height: 180px;
		position: relative;
		padding: 20px;
		line-height: 1.5;
		a {
			display: block;
			height: 100%;
			text-decoration: none !important;
		}
		.is-en {
			font-size: 12px;
			margin-bottom: 5px;
		}
	}
	.small {
		text-align: right;
		font-size: 12px;
		margin-top: 10px;
	}
}

.hrm-block-share {
	display: flex;
	text-align: left;
	text-decoration: none;
	align-items: center;
	width: 310px;
	margin: 0 auto;
	&:hover {
		text-decoration: none;
	}
	&__left {
		width: 55px;
	}
	&__meta {
		font-size: 12px;
		margin-bottom: 5px;
	}
}

.hrm-block-copyright {
	font-size: 12px;
	background: $text-color;
	color: $gray-light;
	text-align: center;
	line-height: 50px;
}

.hrm-block-bullets {
	width: 400px;
	margin-top: 20px;
	text-align: center;
	li {
		display: inline-block;
		width: 14px;
		height: 14px;
		margin: 0 5px;
		vertical-align: top;
		> a {
			display: block;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			border: 1px solid $text-color;
			&.is-active {
				background: $text-color;
			}
		}
	}
}

.hrm-block-row {
	margin: 160px auto;
	&.is-padding {
		padding-top: 160px;
		padding-bottom: 160px;
		margin-top: 0;
		margin-bottom: 0;
	}
	&.is-small {
		margin: 140px auto;
		@at-root .is-padding & {
			padding-top: 140px;
			padding-bottom: 140px;
			margin-top: 0;
			margin-bottom: 0;
		}
	}
	&.is-xsmall {
		margin: 120px auto;
		@at-root .is-padding & {
			padding-top: 120px;
			padding-bottom: 120px;
			margin-top: 0;
			margin-bottom: 0;
		}
	}
}

.hrm-block-photo-item {
	width: 160px;
	position: relative;

	&__description {
		@at-root .is-disabled & {
			opacity: .6;
		}
	}
	a {
		display: inline-block;
		text-decoration: none !important;
		@extend .hrm-alpha-hover;
	}

	&__figure,
	figure {
		position: relative;
		margin-bottom: 10px;
		> img {
			width: 100%;
			@include loading;
			background-color: #FFF;
		}
	}

	&__figure__caption,
	figcaption {
		font-family: $font-family-sans-serif;
		background: #FFF;
		padding: 6px 10px;
		font-size: 14px;
		position: absolute;
		top: 0;
		left: 0;
	}
	&.is-special figcaption {
		background: $text-color;
		color: #FFF;
	}
	&__img {
		background-size: cover;
		background-position: center center;
		width: 160px;
		height: 100px;
		@include loading;
		@at-root .is-disabled & {
			opacity: .6;
		}
	}
	&.is-special {
		&:before {
			content: "";
			position: absolute;
			width: 1px;
			height: 100%;
			background: #FFF;
			left: -20px;
		}
	}
	p {
		line-height: 1.5;
	}
	.is-en {
		font-size: 14px;
	}
	.is-small {
		margin-top: 10px;
		font-size: 12px;
	}
}

.hrm-block-toggle {
	&__hide {
		display: none;
	}
}

.hrm-block-fixed-width {
	width: 900px;
	margin: 0 auto;
}

.hrm-block-text {
	margin: 15px 0;
	line-height: 1.7;
	dl {
		margin-top: 30px;
	}
	dt {
		font-size: 20px;
		font-weight: bold;
		margin-bottom: 5px;
	}
	dd {
		font-size: 16px;
	}
	.hrm-button4 {
		display: block;
		margin: 0 auto;
		span {
			font-size: 14px;
		}
		.hrm-icon-line-arw2 {
			position: absolute;
			right: 20px;
			top: 50%;
			margin-top: -6px;
		}
		&:hover {
			.hrm-icon-line-arw2:before {
				transform: scaleX(1.2);
			}
		}
	}
}

.hrm-block-text2 {
	line-height: 1.7;
	p {
		margin-bottom: 1em;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.hrm-block-table {
	width: 100%;
	line-height: 1.7;
	th {
		background: $gold;
		color: #FFF;
		padding: 1em 1.2em;
	}
	td {
		padding: 1em 1.2em;
		text-align: center;
		border-left: 1px solid $gold;
	}
	tbody {
		tr {
			border-bottom: 1px solid $gold;
			&:nth-child(even) {
				background: #fbf8f4;
			}
			td:first-child {
				border-left: 0;
			}
		}
	}
}

.hrm-block-slide {
	display: table;
	width: 100%;
	@include loading;
	&.hrm-lazy-load {
		background: none;
	}
	&__main {
		display: table-cell;
		background-size: cover;
		background-position: center center;
		width: 84%;
		height: 100%;
	}
	&__sub {
		height: 100%;
		li a {
			width: 100%;
			display: block;
			background-size: cover;
			background-position: center center;
			&:after {
				content: "";
				display: block;
				padding-top: 75%;
			}
			&.is-active {
				opacity: 0.5;
			}
		}
	}
}

.hrm-block-slide2 {
	&__nav {
		position: absolute;
		background: $gray-light;
		top: 50%;
		margin-top: -40px;
		z-index: 1;
		//@extend .hrm-alpha-hover;
		transition: all .2s linear;
		&:hover {
			background: #FFF;
		}
		&.is-next {
			right: 0;
		}
		&.is-prev {
			left: 0;
			i {
				&:before {
					transform: rotate(180deg) translateX(3px);
				}
			}
		}
		span {
			display: table-cell;
			width: 40px;
			height: 80px;
			vertical-align: middle;
			text-align: center;
		}
		i {
			font-size: 12px;
		}
	}
}

.hrm-block-contact {
	display: table;
	width: 100%;
	height: 100px;
	&__col {
		display: table-cell;
		vertical-align: middle;
		text-align: center;
		&.is-first {
			width: 600px;
			background: $gold;
		}
		&.is-last {
			a {
				display: block;
				height: 100px;
				background: $gray-light;
				line-height: 100px;
				text-decoration: none !important;
				font-size: 24px;
				position: relative;
				@extend .hrm-alpha-hover;
				i {
					font-size: 14px;
					position: absolute;
					right: 20px;
					top: 50%;
					line-height: 1;
					margin-top: -7px;
				}
			}
		}
	}

	&__phone {
		display: table;
		margin: 0 auto;
		color: #FFF;
		address {
			font-size: 36px;
			font-style: normal;
		}
		&__col {
			display: table-cell;
			text-align: left;
			vertical-align: middle;
			&.is-first {
				font-size: 24px;
				border-right: 1px solid #FFF;
				padding-right: 25px;
			}
			&.is-last {
				padding-left: 25px;
			}
		}
	}
}

.hrm-block-gmap {
	//padding: 0 50px;
	margin-bottom: 80px;
	width: 980px;
	margin-left: auto;
	margin-right: auto;

	> p {
		text-align: right;
		margin-top: 10px;
		a {
			@extend .hrm-alpha-hover;
		}
	}

	&__map {
		//width: 100%;
		//height: 500px;
		width: 980px;
		height: 500px;
		margin-left: auto;
		margin-right: auto;
		background-size: cover;
		@include loading;
	}
	.hrm-button3 {
		font-size: 14px;
		line-height: 60px;
		i {
			margin-left: 10px;
		}
	}
}

.hrm-block-plans {
	.hrm-block-toggle {
		margin-bottom: 5px;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.hrm-block-modal {
	padding: 55px;
	padding-top: 100px;
	position: relative;
	.hrm-heading3 {
		margin-bottom: 35px;
		.hrm-heading3__title {
			font-size: 36px;
		}
	}
	&__close {
		position: absolute;
		top: 0;
		right: 0;
		font-size: 14px;
		text-align: left;
		a {
			position: relative;
			background: $gray-light;
			display: block;
			width: 100px;
			text-decoration: none !important;
			padding-left: 20px;
			@extend .hrm-alpha-hover;
		}
		span {
			line-height: 60px;
		}
		i {
			position: absolute;
			right: 20px;
			top: 50%;
			margin-top: -6px;
			transform: rotate(45deg);
		}
	}
}

.hrm-block-menu {
	text-align: center;
	dl {
		margin-bottom: 30px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	dt {
		font-size: 14px;
		font-style: italic;
		color: #828282;
		margin-bottom: 5px;
	}
}

.hrm-block-attention-list {
	dl {
		margin-bottom: 40px;
	}
	ul {
		line-height: 1.7;
		font-size: 16px;
		margin-top: 10px;
		list-style: disc;
		margin-left: 1.5em;
		li {
			line-height: 2;
			margin-bottom: .5em;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	p {
		font-size: 16px;
		margin-top: 10px;
	}
}

.hrm-block-pageback {
	.hrm-button3 {
		display: block;
		width: 250px;
		position: relative;
		margin: 80px auto;
		i {
			position: absolute;
			right: 15px;
			top: 50%;
			margin-top: -10px;
		}
	}
}


.hrm-block-defalut-header {
	width: 100%;
	background: #FFF;
	text-align: center;
	vertical-align: middle;
	border-bottom: 1px solid $gray-light;
	&__logo {
		padding: 30px 0;
	}
}

.hrm-block-404 {
	width: 100%;
	height: calc(100vh - 89px - 81px - 110px - 50px);
	display: table;
	> .inner {
		display: table-cell;
		vertical-align: middle;
		text-align: center;
		padding: 100px 0;
	}
	.content {
		dl {
			background: #f9f9f9;
			padding: 40px;
			width: 900px;
			margin: 0 auto;
		}
		dd {
			font-size: 14px;
		}
		dt {
			text-align: center;
			font-size: 30px;
			margin-bottom: 20px;
		}
		dd {
			line-height: 2;
		}
	}
}
