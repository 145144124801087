//animation: circle .75s forwards;

@keyframes lazy-load {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes news  {
	0% {
		display: block;
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
