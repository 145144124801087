@mixin tagline {
	font-size: 14px;
	line-height: 1;
	margin-top: 10px;
	letter-spacing: .05em;
}

.hrm-heading {
	position: relative;
	&:before {
		content: "";
		background: $text-color;
		height: 2px;
		width: 20px;
		position: absolute;
		top: -15px;

		@at-root .is-page & {
			background: $gold;
			width: 30px;
		}
	}

	&__title {
		font-weight: normal;
		font-size: 46px;
		line-height: 1;
		transform: translateX(-8px);
	}

	&__tagline {
		font-size: 14px;
		line-height: 1;
		margin-top: 10px;
		letter-spacing: .05em;
	}
}

.hrm-heading2 {
	&__title {
		font-size: 32px;
		line-height: 1.7;
		font-weight: normal;
	}
}

.hrm-heading3 {
	text-align: center;
	&__title {
		font-weight: normal;
		font-size: 46px;
		position: relative;
		&:before {
			content: "";
			background: $text-color;
			width: 2px;
			position: absolute;
			left: 50%;
			top: -25px;
			margin-left: -1px;
			height: 20px;
			@at-root .is-page & {
				top: -35px;
				height: 30px;
			}
		}
		@at-root .is-page & {
			&:before {
				background: $gold;
			}
		}
	}
	&__tagline {
		@include tagline;
	}
}

.hrm-heading4 {
	font-size: 32px;
	//font-style: italic;
	line-height: 1.7;
	font-weight: normal;
	&:after {
		content: "";
		height: 1px;
		width: 160px;
		background: $gold;
		display: block;
		position: relative;
		left: -120px;
		top: 15px;
		@at-root .is-reverse & {
			left: calc(50% + 166px);
		}
	}
	&.is-en {
		.text {
			font-size: 36px;
			line-height: 1.5;
		}
		font-size: 24px;
	}
}

.hrm-heading5 {
	font-size: 24px;
	font-style: italic;
	line-height: 2.5;
	border-bottom: 1px solid $gold;
	font-weight: normal;
	margin-bottom: 10px;
}
