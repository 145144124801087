$photo_opaciy: .8;

a.hrm-card {
	&:hover {
		.hrm-icon-line-arw:before {
			transform: scaleX(3);
		}
	}
}

.hrm-card {
	&__main {
		background: $gray-light;
		padding: 30px 30px 60px 30px;
		display: block;
		margin: 0 20px;
		position: relative;
		top: -20px;
		height: 200px;
	}

	&__photo {
		overflow: hidden;
		position: relative;
		transition: opacity .3s linear;
		@include loading;
		@at-root a:hover & {
			opacity: $photo_opaciy;
		}
		&:after {
			content: "";
			display: inline-block;
			padding-top: 44.4%;
		}
	}

	&__meta {
		font-family: $font-family-sans-serif;
		font-size: 14px;
		margin-bottom: 10px;
	}

	&__name {
		font-weight: normal;
		font-size: 22px;
		line-height: 1.7;
	}

	&__link {
		text-align: right;
		position: relative;
		top: 5px;
	}

	&__info {
		position: absolute;
		right: 25px;
		bottom: 20px;
		font-size: 18px;
		font-style: italic;
	}
}

a.hrm-card {
	text-decoration: none;
	&:hover {
		text-decoration: none;
		.hrm-card__photo {
			> img {
				opacity: 0.8;
			}
		}
	}
}

.hrm-card2 {
	text-decoration: none;
	display: block;
	color: $gray-light;
	&:hover {
		text-decoration: none;
		color: $gray-light;

		.hrm-card2__photo {
			> img {
				opacity: $photo_opaciy;
			}
		}

		.hrm-icon-ext {
			&:before {
				transform: scaleX(1.5);
			}
		}
	}
	.hrm-card2__photo {
		overflow: hidden;
		> img {
			@include loading;
			transition: opacity .3s linear;
		}
	}
	&__main {
		background: $text-color;
		padding: 20px;
		position: relative;
	}

	&__meta {
		font-family: $font-family-sans-serif;
		font-size: 14px;
		margin-bottom: 10px;
	}

	&__name {
		font-weight: normal;
	}

	&__link {
		position: absolute;
		right: 20px;
		top: 50%;
		margin-top: -15px;
	}
}
