.hrm-container {
	//@include container-fixed;
	width: 100%;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;
	background: #FFF;
}

.row-g-10 {
	@include gen-gutter-row(10px);
}
