.hrm-main-banner-nav {
	background: rgba(0, 0, 0, 0.2);
	display: table;
	padding: 20px;
	width: 100%;
	color: #FFF;
	a {
		color: #FFF;
		text-decoration: none;
		position: relative;

	}
	&__cell {
		display: table-cell;
		vertical-align: middle;
		&.is-menu a {
			&:before {
				content: "";
				position: absolute;
				top: -10px;
				background: #FFF;
				width: 0;
				height: 2px;
				transition: width .3s $easeOutExpo;
			}
			&:hover {
				&:before {
					width: 20px;
				}
			}
		}
	}

	// ロゴ
	&__cell.is-logo {
		width: 165px;
		text-align: center;
	}

	// メニュー
	&__cell.is-menu {
		font-size: 20px;
		ul {
			display: flex;
			padding: 0 20px;
		}
		li a {
			padding: 0 25px;
		}
	}

	// 言語選択
	&__cell.is-lang {
		width: 165px;
		ul {
			display: flex;
			justify-content: center;
		}
		li {
			padding: 0 5px;
		}
		.hrm-icon-lang {
			@include hide-text();
			display: inline-block;
			vertical-align: top;
			&.is-jp.is-active {
				@include sprite($icon_jp_active);
			}
			&.is-jp {
				@include sprite($icon_jp);
			}
			&.is-en.is-active {
				@include sprite($icon_en_active);
			}
			&.is-en {
				@include sprite($icon_en);
			}
		}
	}

}

// 下層ページ用
.hrm-main-banner-nav.is-page {
	.hrm-main-banner-nav__cell.is-menu {
		text-align: center;
		ul {
			justify-content: center;
			font-size: 18px;
		}
	}
}
