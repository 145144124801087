// gutter生成mixin
@mixin gen-gutter-row($gutter: 0px) {
	// bootstrapのrowクラス生成mixinを利用
	@include make-row($gutter);
	[class^="col-"] {
		padding-left: $gutter / 2;
		padding-right: $gutter / 2;
	}
}

// アイコン生成
@mixin radius-icon($size) {
	text-align: center;
	display: inline-block;
	border-radius: 50%;
	line-height: #{$size}px;
	width: #{$size}px;
	height: #{$size}px;
}

// line arwヘルパー
@mixin line-arw-set($line_width: 20, $slide_to: 0) {
	transform: translateX(#{$slide_to}px);
	// line
	&:before {
		width: #{$line_width}px;
	}
}

@mixin loading {
	background-image: url(../img/loading.gif);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 44px 44px;
}

// retina mixin
@mixin r-sprite-width($sprite) {
	width: nth($sprite, 5)/2;
}

@mixin r-sprite-height($sprite) {
	height: nth($sprite, 6)/2;
}

@mixin r-sprite-position($sprite) {
	$sprite-offset-x: nth($sprite, 3)/2;
	$sprite-offset-y: nth($sprite, 4)/2;
	background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin r-sprite-size($sprite) {
	background-size: nth($sprite, 7)/2 nth($sprite, 8)/2;
}

@mixin r-sprite($sprite) {
	@include sprite-image($sprite);
	@include r-sprite-position($sprite);
	@include r-sprite-width($sprite);
	@include r-sprite-height($sprite);
	@include r-sprite-size($sprite);
}
