/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
$icon-en-name: 'icon_en';
$icon-en-x: 0px;
$icon-en-y: 0px;
$icon-en-offset-x: 0px;
$icon-en-offset-y: 0px;
$icon-en-width: 41px;
$icon-en-height: 41px;
$icon-en-total-width: 84px;
$icon-en-total-height: 84px;
$icon-en-image: '../img/pc/sprite.png';
$icon-en: (0px, 0px, 0px, 0px, 41px, 41px, 84px, 84px, '../img/pc/sprite.png', 'icon_en', );
$icon-en-active-name: 'icon_en_active';
$icon-en-active-x: 43px;
$icon-en-active-y: 0px;
$icon-en-active-offset-x: -43px;
$icon-en-active-offset-y: 0px;
$icon-en-active-width: 41px;
$icon-en-active-height: 41px;
$icon-en-active-total-width: 84px;
$icon-en-active-total-height: 84px;
$icon-en-active-image: '../img/pc/sprite.png';
$icon-en-active: (43px, 0px, -43px, 0px, 41px, 41px, 84px, 84px, '../img/pc/sprite.png', 'icon_en_active', );
$icon-jp-name: 'icon_jp';
$icon-jp-x: 0px;
$icon-jp-y: 43px;
$icon-jp-offset-x: 0px;
$icon-jp-offset-y: -43px;
$icon-jp-width: 41px;
$icon-jp-height: 41px;
$icon-jp-total-width: 84px;
$icon-jp-total-height: 84px;
$icon-jp-image: '../img/pc/sprite.png';
$icon-jp: (0px, 43px, 0px, -43px, 41px, 41px, 84px, 84px, '../img/pc/sprite.png', 'icon_jp', );
$icon-jp-active-name: 'icon_jp_active';
$icon-jp-active-x: 43px;
$icon-jp-active-y: 43px;
$icon-jp-active-offset-x: -43px;
$icon-jp-active-offset-y: -43px;
$icon-jp-active-width: 41px;
$icon-jp-active-height: 41px;
$icon-jp-active-total-width: 84px;
$icon-jp-active-total-height: 84px;
$icon-jp-active-image: '../img/pc/sprite.png';
$icon-jp-active: (43px, 43px, -43px, -43px, 41px, 41px, 84px, 84px, '../img/pc/sprite.png', 'icon_jp_active', );
$spritesheet-width: 84px;
$spritesheet-height: 84px;
$spritesheet-image: '../img/pc/sprite.png';
$spritesheet-sprites: ($icon-en, $icon-en-active, $icon-jp, $icon-jp-active, );
$spritesheet: (84px, 84px, '../img/pc/sprite.png', $spritesheet-sprites, );

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
