.hrm-news {
	$breakpoint: 1230px;
	background: #f9f9f9;
	position: relative;

	a {
		@extend .hrm-alpha-hover;
	}

	ul > li {
		margin-bottom: 35px;
		&:last-child {
			margin-bottom: 0;
		}
		// 3番目以降は非表示
		&:nth-of-type(n+4) {
			display: none;
			opacity: 0;
			transition: opacity .3s linear;
		}
	}

	&.is-active {
		ul > li {
			&:nth-of-type(n+4) {
				display: block;
				animation: news .5s forwards;
			}
		}

	}

	&__content {
		padding: 100px 0;
		position: relative;
		margin-left: auto;
		margin-right: auto;
		width: 800px;
		@include clearfix;
		@media (min-width: $breakpoint) {
			margin-left: 285px;
			width: auto;
		}
	}

	&__link {
		position: absolute;
		bottom: 60px;
		right: 50px;
		a {
			text-decoration: none;
			> i {
				position: relative;
				top: -3px;
				margin-left: 10px;
			}
		}
	}

	&__title {
		float: left;
		width: 195px;
	}

	&__list {
		margin-left: 230px;
		@media (min-width: $breakpoint) {
			max-width: 1000px;
			padding: 10px 50px 10px 0;
		}
	}

	&__meta {
		margin-bottom: 10px;
		time {
			font-size: 14px;
			line-height: 21px;
			margin-right: 18px;
		}
	}

	&__text {
		line-height: 1.7;
	}
}
