.hrm-icon-toggle {
	display: inline-block;
	width: 28px;
	height: 28px;
	vertical-align: middle;
	background: url(../img/svg/icon-plus.svg);
	background-size: cover;
	transition: transform .3s ease;
	@at-root .is-close & {
		transform: rotate(225deg);
	}
}

.hrm-icon-toggle2 {
	display: inline-block;
	width: 11px;
	height: 11px;
	vertical-align: middle;
	background: url(../img/svg/icon-plus2.svg);
	background-size: cover;
}

.hrm-icon-line-arw {
	position: relative;
	display: inline-block;
	i {
		display: inline-block;
		width: 31px;
		height: 31px;
		background: url(../img/svg/icon-arr_31px.svg);
		background-size: cover;
		vertical-align: top;
	}
	&:before {
		content: "";
		height: 1px;
		width: 50px;
		background: $text-color;
		position: absolute;
		top: 50%;
		left: -55px;
		transform-origin: right center;
		transition: all .3s ease;
	}
	//&:hover {
	//	&:before {
	//		transform: scaleX(1.5);
	//	}
	//}
}

.hrm-icon-line-arw2 {
	display: inline-block;
	vertical-align: middle;
	height: 12px;
	line-height: 12px;
	position: relative;
	&.is-small {
		height: 5px;
		line-height: 5px;
	}
	i {
		display: inline-block;
		background: url(../img/svg/icon-arw_white.svg);
		background-size: cover;
		width: 10px;
		height: 12px;
		line-height: 12px;
	}
  .black {
    display: inline-block;
    background: url(../img/svg/icon-arw.svg);
    background-size: cover;
    width: 10px;
    height: 12px;
    line-height: 12px;
  }
	&.is-small i {
		width: 5px;
		height: 5px;
		line-height: 5px;
	}
	&:before {
		content: "";
		height: 1px;
		width: 50px;
		background: $text-color;
		position: absolute;
		top: 50%;
		left: -55px;
		transform-origin: right center;
		transition: all .3s ease;
	}
	&.is-small {
		&:before {
			content: "";
			height: 1px;
			width: 20px;
			// background: $text-color;
			background: #fff;
			position: absolute;
			top: 50%;
			left: -23px;
			transform-origin: right center;
			transition: all .3s ease;
			margin-top: 1px;
		}
	}
}
.hrm-icon-line-arw3 {
  display: inline-block;
  vertical-align: middle;
  height: 12px;
  line-height: 12px;
  position: relative;
  &.is-small {
    height: 5px;
    line-height: 5px;
  }
  i {
    display: inline-block;
    background: url(../img/svg/icon-arw.svg);
    background-size: cover;
    width: 10px;
    height: 12px;
    line-height: 12px;
  }
  &.is-small i {
    width: 5px;
    height: 5px;
    line-height: 5px;
  }
  &:before {
    content: "";
    height: 1px;
    width: 50px;
    background: $text-color;
    position: absolute;
    top: 50%;
    left: 0px;
    transform-origin: right center;
    transition: all .3s ease;
  }
  &.is-small {
    &:before {
      content: "";
      height: 1px;
      width: 20px;
      background: $text-color;
      // background: #fff;
      position: absolute;
      top: 50%;
      left: -23px;
      // transform-origin: right center;
      // transition: all .3s ease;
      margin-top: 1px;
    }
  }
}

.hrm-icon-arw {
	display: inline-block;
	vertical-align: middle;
	background: url(../img/svg/icon-arr_15px.svg);
	background-size: cover;
	width: 15px;
	height: 15px;
	&.is-white {
		background: url(../img/svg/icon-arr_15px_white.svg);
	}
}

.hrm-icon-arw2 {
	display: inline-block;
	vertical-align: middle;
	background: url(../img/svg/icon-arr_31px.svg);
	background-size: cover;
	width: 31px;
	height: 31px;
	&.is-white {
		background: url(../img/svg/icon-arr_31px_white.svg);
	}
}

.hrm-icon-arw3 {

}

.hrm-icon-facebook {
	display: inline-block;
	width: 40px;
	height: 40px;
	background: url(../img/svg/icon-facebook.svg);
	background-size: cover;
	vertical-align: middle;
	vertical-align: middle;
}

.hrm-icon-back {
	display: inline-block;
	width: 25px;
	height: 14px;
	background: url(../img/svg/icon-arr_back.svg);
	background-size: cover;
}

.hrm-icon-map {
	display: inline-block;
	vertical-align: -5px;
	width: 23px;
	height: 21px;
	background: url(../img/svg/map_big.svg);
	background-size: cover;
}

.hrm-icon-ext {
	display: inline-block;
	position: relative;
	i {
		display: inline-block;
		width: 31px;
		height: 31px;
		background-size: cover;
		background: url(../img/svg/icon-new_window.svg);
	}
	&:before {
		content: "";
		height: 1px;
		width: 50px;
		background: #FFF;
		position: absolute;
		top: 50%;
		left: -55px;
		transform-origin: right center;
		transition: all .3s ease;
	}
}
