.hrm-button {
	height: 80px;
	//background: $gray-light;
	width: 100%;
	overflow: hidden;
	position: relative;
	transition: opacity .3s linear;
	&__img {
		vertical-align: middle;
		margin-right: 10px;
	}
	&:before {
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		background: $gray-light;
		transition: all .3s ease;
	}
	&:hover {
		//opacity: .8;
		&:before {
			opacity: .5;
		}
	}
	> a {
		display: block;
		text-decoration: none;
		height: 100%;
		line-height: 80px;
		text-align: right;
		padding: 0 40px;
		position: relative;
		> i {
			position: relative;
			top: -3px;
			margin-left: 10px;
		}
	}
}

.hrm-button2 {
	display: block;
	height: 100px;
	width: 100%;
	background: $gray-light;
	text-decoration: none !important;
	padding: 14px 20px;
	font-size: 18px;
	line-height: 1.5;
	position: relative;
	@extend .hrm-alpha-hover;
	&:hover {
		.hrm-icon-line-arw2 {
			&:before {
				transform: scaleX(2);
			}
		}
	}
	&__label {
		font-style: italic;
		font-size: 12px;
		display: block;
	}
	&.is-active {
		background: $gold;
		color: #FFF;
		.hrm-icon-line-arw2 {
			display: none;
		}
	}
	.hrm-icon-line-arw2 {
		position: absolute;
		right: 20px;
		bottom: 10px;
	}
}

.hrm-button3 {
	background: $gray-light;
	font-size: 16px;
	text-decoration: none !important;
	line-height: 50px;
	padding: 0 20px;
	display: inline-block;
	@extend .hrm-alpha-hover;
	.hrm-icon-line-arw2 {
		position: absolute;
		right: 20px;
		top: 50%;
		margin-top: -6px;
	}
	&:hover {
		.hrm-icon-line-arw2 {
			&:before {
				transform: scaleX(1.5);
			}
		}
	}
}

.hrm-button4 {
	display: inline-block;
	background: $gray-light;
	text-decoration: none !important;
	line-height: 60px;
	padding: 0 20px;
	width: 250px;
	position: relative;
	@extend .hrm-alpha-hover;
	.hrm-icon-line-arw2 {
		position: absolute;
		right: 20px;
		top: 50%;
		margin-top: -6px;
	}
	&:hover {
		.hrm-icon-line-arw2 {
			&:before {
				transform: scaleX(1.5);
			}
		}
	}
}

.hrm-button-pagetop {
	background: $gray-light;
	width: 80px;
	height: 80px;
	display: inline-block;
	text-align: center;
	text-decoration: none;
	i {
		line-height: 80px;
		&:before {
			transform: rotate(-90deg);
		}
	}
}
