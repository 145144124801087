// normalize.cssの初期化

* {
	box-sizing: border-box;
}

body,
h1,h2,h3,h4,h5,h6,
p,
ul,ol,dl,
table,blockquote {
	margin: 0;
	padding: 0;
}
html {
	min-height: #{$min_body_height}px;
	-webkit-font-smoothing: antialiased;
}
body {
	min-width: $global_min_width;
	background: #DDD;
	&.is-hide {
		visibility: hidden;
	}
}
h1,h2,h3,h4,h5,h6 {
	line-height: 1.2;
}
ul,ol {
	list-style: none;
}
img {
	vertical-align: top;
}

dl {
	margin: 0;
	padding: 0;
	dd {
		margin: 0;
		padding: 0;
	}
}

a {
	color: $link-color;
	text-decoration: underline;
	&:hover,
	&:focus {
		color: inherit;
		text-decoration: underline;
	}
	&:focus {
		outline: none;
	}
}

address {
	margin-bottom: 0;
}
