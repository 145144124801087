.hrm-news-page {
	background: #fbf8f4;
	padding-top: 120px;
	position: relative;

	&__nav {
		position: absolute;
		top: 50%;
		margin-top: -15px;
		&:hover {
			.hrm-icon-line-arw:before {
				transform: scaleX(1.3);
			}
		}
		&.is-next {
			right: -120px;
		}
		&.is-prev {
			left: -120px;
			transform: rotate(180deg);
		}
	}

	&__row {
		height: 258px;
		width: 70%;
		@media (min-width: 1200px) {
			width: 80%;
		}
		border-left: 1px solid $gold;
		border-right: 1px solid $gold;
		margin: 50px auto 0 auto;
	}
	.hrm-news-page__item:last-child .hrm-news-page__item__inner {
		border-right: 0 !important;
	}
}

.hrm-news-page__item {
	height: 100%;
	a {
		text-decoration: none !important;
		display: block;
		width: 100%;
		height: 100%;
		transition: opacity .2s linear;
		&:hover {
			opacity: .5;
		}
	}
	&__inner {
		padding: 0 40px;
		border-right: 1px solid $gold;
		position: relative;
		height: 100%;
		min-height: 255px;
	}
	&__meta {
		font-size: 14px;
		span {
			color: $gold;
			font-size: 12px;
			&:before {
				content: "/";
				display: inline-block;
				color: $text-color;
				margin: 0 .5em;
			}
		}
	}
	&__text {
		margin-top: 15px;
		line-height: 1.7;
	}
	&__link {
		position: absolute;
		bottom: 20px;
		right: 40px;
		font-size: 16px;
		i {
			position: relative;
			top: -2px;
			margin-left: 5px;
		}
	}
}
