$min_width: 400;
$max_width: 1000;

.hrm-life-style {
	height: 100%;
	width: 100%;
	//min-height: 900px;
	background: $gray-light;
	position: absolute;
	top: 0;
	left: 0;
	z-index: index($z, life-style);
	//display: none;

	&__render {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	@at-root .is-vertical & {
		//height: #{$max_full_size_height}px !important;
	}

	&__row {
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__col {
		position: relative;
	}

	.hrm-slide-nav {
		position: absolute;
		top: 50%;
		margin-top: -26px;
		.hrm-slide-nav__sub i {
			top: 26px;
		}
		&.is-prev {
			left: 20px;
		}
		&.is-next {
			right: 20px;
		}
		.hrm-slide-nav__text {
			font-size: 22px;
			font-style: italic;
		}
	}
}

.hrm-life-style__title {
	display: table;
	position: absolute;
	top: 0;
	transform: translate(-11vw, -45px);

	&__col {
		display: table-cell;
	}

	.hrm-life-style__title__col:first-child {
		text-align: right;
		padding-right: 14px;
		width: 230px;
		time {
			display: block;
			font-style: italic;
			font-size: 32px;
		}
		span {
			font-size: 14px;
			text-align: left;
			display: inline-block;
		}
	}

	.hrm-life-style__title__col:last-child {
		color: $gold;
		font-size: 60px;
		vertical-align: top;
		border-left: 2px solid $text-color;
		padding-left: 14px;
	}

}

.hrm-life-style__photo {
	width: 52vw;
	min-width: 700px;
	background-size: cover;
	background-position: center center;
	position: relative;
	left: -3vw;
	img {
		width: 100%;
		height: auto;
	}
}

.hrm-life-style__text {
	$min_font: 10;
	$max_font: 14;
	font-size: calc( #{$min_font}px + (#{$max_font} - #{$min_font}) * ( (100vw - #{$min_width}px) / ( #{$max_width} - #{$min_width}) ));

	background: #FFF;
	position: absolute;
	top: 2em;
	right: -10em;
	width: 20em;
	font-style: italic;
	padding: 3em 2em;
	line-height: 2;
	p {
		margin-bottom: 2em;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.hrm-life-style__back {
	font-size: 20px;
	position: absolute;
	top: 50px;
	right: 50px;
	a {
		text-decoration: none !important;
	}
}
