.hrm-global-header {
	position: relative;
	@include clearfix;
	height: 200px;

	a {
		text-decoration: none !important;
	}

	&__main {
		position: absolute;
		width: 100%;
		text-align: center;
		top: 40px;
	}
	&__sub {
		width: 200px;
		position: absolute;
		right: 20px;
		bottom: 24px;
	}

}

.hrm-global-header__lang {
	text-align: right;
	&:after {
		content: "";
		display: block;
		background: $text-color;
		height: 1px;
		margin-top: 10px;
	}
	li {
		display: inline-block;
		font-size: 14px;
		line-height: 1;
		&:last-child {
			&:before {
				content: "/";
				display: inline-block;
				margin: 0 .5em;
			}
		}
		> a {
			position: relative;
			&:before {
				transition: width .2s ease;
				content: "";
				position: absolute;
				height: 2px;
				width: 0;
				background: $gold;
				top: -10px;
			}
			&.is-active {
				&:before {
					width: 16px;
				}
			}
		}
    &.invalid {
      a {
        pointer-events: none;
        color: #e5e5e5;
      }
    }
	}
}

.hrm-global-header__address {
	display: table;
	margin: 0 auto;
	padding: 10px 0;
	dt {
		display: table-cell;
		font-size: 14px;
		padding-right: 10px;
	}
	dd {
		display: table-cell;
		margin: 0;
		padding: 0;
		font-size: 20px;
	}
}

.hrm-global-header__links {
	display: flex;
	justify-content: space-between;
	li {
		font-size: 14px;
		&:first-child {
			width: 149px;
			line-height: 50px;
			a {
				padding-left: 10px;
				position: relative;
				@extend .hrm-alpha-hover;
			}
			.arw {
				position: absolute;
				right: 10px;
				top: 50%;
				line-height: 5px;
				margin-top: -6px;
				display: inline-block;
			}
		}
		&:last-child {
			a {
				display: table;
				width: 50px;
				height: 50px;
				@extend .hrm-alpha-hover;
				i {
					display: table-cell;
					vertical-align: middle;
					text-align: center;
					font-size: 12px;
					transform: translateY(3px);
				}
			}
		}
		> a {
			display: block;
			background: $gray-light;
		}
	}
}
