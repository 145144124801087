.hrm-slide-nav {

	a {
		display: flex;

		color: #FFF !important;
		text-decoration: none !important;
	}

	&.is-next a {
		flex-direction: row-reverse;
	}

	&.is-dark a {
		color: $text-color !important;
	}

	&.is-dark .hrm-icon-arw3 {
		border: 1px solid $text-color !important;
	}

	&__sub {
		.hrm-icon-arw2 {
			transform: translateY(8px);
			&.is-prev {
				transform: translateY(8px) rotate(180deg);
			}

		}
	}

	&__main {
		//margin: 0 8px;
		text-align: center;
		width: 3.5em;
	}

	&__text {
		font-size: 14px;
		line-height: 1;
		&:after {
			content: "";
			display: block;
			height: 1px;
			background: #FFF;
			width: 90%;
			margin: 10px 0 10px 0;
			position: relative;
			left: 5%;
			transition: all .3s $easeOutExpo;

			@at-root .is-dark & {
				background: $text-color;
			}

			// アニメーション用の設定
			@at-root .hrm-slide-nav:hover & {
				transform: scaleX(1.5);
			}
			@at-root .is-prev & {
				transform-origin: left center;
			}
			@at-root .is-next & {
				transform-origin: right center;
			}
		}
	}

	&__text2 {
		writing-mode: vertical-lr;
		font-size: 24px;
		letter-spacing: .2em;
		// IEバグ対応
		display: inline-block;
		vertical-align: top;
		// IEの縦文字対応
		@at-root .is-ie & {
			position: relative;
			left: -5px;
		}
	}

}
