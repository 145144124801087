.hrm-photo {
	//background-size: cover;
	//background-position: center center;
	@include loading;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.hrm-photo-set {
	position: absolute;
	top: 0;
	left: 0;
	&__inner {
		position: relative;
		width: 100%;
		height: 100%;
	}
	&__description {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		color: #FFF;
		font-size: 14px;
		display: none;
		background: rgba(0, 0, 0, .8);
		line-height: 34px;
		padding: 0 8px;
		font-family: $font-family-sans-serif;
		@at-root .hrm-lazy-load & {
			display: block;
		}
	}
}
