.hrm-h-yakumono__f {
	margin-left: -.4em;
}

.hrm-h-yakumono__r {
	margin-right: -.4em;
}

.hrm-h-fc-gold {
	color: $gold;
}

.hrm-lazy-load {
	background-size: cover !important;
	animation: lazy-load .5s linear;
	&.is-bottom {
		background-position: center bottom;
	}
	&.is-top {
		background-position: center top;
	}
	.hrm-photo {
		background-size: cover;
	}
}

.hrm-lazy-load--slow {
	background-size: cover !important;
	animation: lazy-load 1s linear;
	&.is-bottom {
		background-position: center bottom;
	}
	&.is-top {
		background-position: center top;
	}
	.hrm-photo {
		background-size: cover;
	}
}

img.hrm-lazy-load {
	background: none !important;
}

.hrm-h-small {
	font-size: 14px;
}

.hrm-h-medium {
	font-size: 16px;
}

.hrm-h-ul {
	li {
		list-style: disc;
		line-height: 1.7;
		margin-left: 1.5em;
	}
}

.hrm-h-attention-list {
	font-size: 14px;
	margin-top: 2em;
	li {
		line-height: 1.7;
		text-indent: -1em;
		padding-left: 1em;
		margin-bottom: .5em;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.hrm-alpha-hover {
	opacity: 1;
	transition: opacity .2s linear;
	&:hover {
		opacity: .5;
	}
}

.mb00 {
	margin-bottom: 0 !important;
}

.mb15 {
	margin-bottom: 15px !important;
}

.mb30 {
	margin-bottom: 30px !important;
}

.mt30 {
	margin-top: 30px !important;
}

.mb70 {
	margin-bottom: 70px;
}

.mb80 {
	margin-bottom: 80px;
}

#mask_rect {
	transition: all .5s ease;
}

//#mask_rect {
//	transition: all 1s ease;
//	transform:translateX(-500px);
//	&.is-on {
//		transform:translateX(0px);
//	}
//}

.text-center {
	text-align: center;
}
