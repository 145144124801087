.hrm-small-nav {
	width: 100%;
	background: $gray-light;
	//border: 1px solid $gray-light;

	&.is-fixed {
		position: fixed;
		top: 0;
		left: 0;
		z-index: index($z, small-nav);
		transform: translateY(-100px);
		transition: all 1s $easeOutExpo;
		min-width: $global_min_width;
	}

	&.is-active {
		transform: translateY(0);
	}

	a {
		text-decoration: none;
	}
	&__inner {
		display: table;
		width: 100%;
		height: 100%;
	}

	&__cell {
		display: table-cell;
		vertical-align: middle;
	}

	// ロゴ
	&__cell.is-logo {
		background: #FFF;
		width: 235px;
		padding: 26px 40px;
		text-align: center;
		border-bottom: 1px solid $gray-light;
		min-height: 80px;
		@at-root .is-page & {
			padding: 12px 40px;
		}
	}

	// メニュー
	&__cell.is-menu {
		font-size: 20px;
		padding: 0 25px;
		@at-root .is-page & {
			padding: 0 0 0 26px;
		}
		ul {
			//display: flex;
			@include clearfix;
		}
		li a {
			float: left;
			padding: 0 25px;
			line-height: 1;
			position: relative;
			&:before {
				content: "";
				position: absolute;
				top: -10px;
				background: $text-color;
				width: 0;
				height: 2px;
				transition: width .3s $easeOutExpo;
				@at-root .is-page & {
					top: -15px;
				}
			}
			&:hover {
				&:before {
					width: 20px;
				}
			}
			&.is-active {
				&:before {
					width: 20px;
				}
			}
		}
		@at-root .is-page & {
			ul {
				//justify-content: space-around;
			}
			li a {
				padding: 0 14px;
				font-size: 16px;
				&:before {
					background: $gold;
				}
			}
		}
	}

	// 言語選択
	&__cell.is-lang {
		width: 185px;
		ul {
			display: flex;
			justify-content: center;
		}
		li {
			padding: 0 5px;
		}
		.hrm-icon-lang {
			@include radius-icon(40);
			border: 1px solid $text-color;
			font-size: 14px;
			&.is-active {
				background: $text-color;
				color: $gray-light;
			}
		}
	}

	// 予約
	&__cell.is-reserve {
		// background: $gold;
		color: $text-color;
		letter-spacing: -0.01em;
		padding: 13px 14px;
		width: 160px;
		> p {
			font-size: 14px;
			position: relative;
			span {
				display: inline-block;
				background: $gold;
				position: relative;
				padding-right: 10px;
			}
			&:before {
				content: "";
				width: 100%;
				height: 1px;
				position: absolute;
				left: 0;
				top: 50%;
				background: #FFF;
			}
		}
		> address {
			// font-size: 20px;
			font-size: 18px;
			font-style: italic;
			margin-top: 5px;
		}
	}

	// プラン
	&__cell.is-plan {
		background: $gold;

		font-size: 14px;
		height: 100%;
		vertical-align: middle;
		width: 160px;
		.hrm-icon-line-arw2 {
			position: absolute;
			top: 50%;
			// right: 15px;
			right: 9px;
			margin-top: -5px;
		}
		a {
			display: table;
			width: 100%;
			height: 80px;
			overflow: hidden;
			@extend .hrm-alpha-hover;
			&:hover {
				.hrm-icon-line-arw2 {
					&:before {
						transform: scaleX(1.5);
					}
				}
			}
		}
		.inner {
			color: #fff;
			display: table-cell;
			width: 100%;
			height: 100%;
			vertical-align: middle;
			padding: 9px;
			position: relative;
		}
	}

	// メール
	&__cell.is-email {
		width: 50px;
		border-left: 1px solid #FFF;
		font-size: 14px;
		text-align: center;
		a {
			display: inline-block;
			height: 100%;
			width: 100%;
			vertical-align: middle;
			position: relative;
			@extend .hrm-alpha-hover;
		}
		i {
			position: absolute;
			top: 50%;
			left: 50%;
			font-size: 15px;
			transform: translate(-50%, -50%);
		}
	}

}
