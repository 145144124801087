// bootstrap
$grid-columns     : 24;
$grid-gutter-width: 0;
$font-family-base : "Times New Roman", YuMincho, "游明朝", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
$font-size-base: 18px;
$text-color: #232221;
$link-color: $text-color;
$screen-lg: 1920px; // max_width

// size
$max_window_width: $screen-lg;
$min_body_height: 650;
$max_full_size_height: 750;
$global_min_width: 1009px;

// color
$gray-light: #e9e8e8;
$gold: #c9a063;

// font
$font-family-sans-serif: "游ゴシック" , "Yu Gothic" , sans-serif;

// easing
$easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715);
$easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
$easeInOutSine: cubic-bezier(0.445, 0.05, 0.55, 0.95);
$easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);

$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);

$easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeInOutQuint: cubic-bezier(0.86, 0, 0.07, 1);
$easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo: cubic-bezier(1, 0, 0, 1);

$easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.15, 0.86);
$easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);

$customEase: cubic-bezier(0.000, 0.965, 0.765, 0.975);

// 配列にクラス名をいれる
$z: small-nav, life-style;
$z_sp: main-banner-nav, contact, menu-list, global-header, global-nav;

//.header {
//	// index番号をz-indexに指定する
//	z-index: index($z, hoge);
//}
